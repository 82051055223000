import $ from 'jquery';

export class Forms {
    constructor() {
        this.classFormGroup = '.js-form-group';
        this.classLetterAmount = '.js-letter-counter-amount';
        this.classField = '.js-letter-counter-field';
        this.classEmptyFieldBtn = '.js-empty-field-btn';
        this.classFormText = 'p.form-text';
        this.elementsForAvoidCopyPaste = 'input[type=password]';
        this.numberOfinvaliFormFields = 0;
    }

    /**
     * Calculates the available length of letters one may type.
     * It's also possible to bypass the calculation to set the number
     * directly with a wrapper function for instance.
     * @param {Object} $field - jQueryObject
     * @param {number} lengthInfo - Number to bypass calculation
     * @returns {number} - Amount of letters that are left to type
     */
    calcLength($field, lengthInfo) {
        let currentLength = 0;
        let remainingLength = 0;
        let maxLength = 0;

        if (lengthInfo) {
            remainingLength = lengthInfo;
        } else {
            currentLength = parseInt($field.val().length, 10);
            maxLength = parseInt($field.attr('maxlength'), 10);
            remainingLength = maxLength - currentLength;
        }

        return remainingLength;
    }

    /**
     * Sets the amount of available text length to the info area of the field.
     * @param {Object} $field - jQueryObject
     * @param {number} lengthInfo - Number to bypass calculation and set it directly
     */
    handleLetterCounter($field, lengthInfo) {
        const remainingLength = this.calcLength($field, lengthInfo);

        $field
            .closest(this.classFormGroup)
            .find(this.classLetterAmount)
            .text(remainingLength)
        ;

        if (remainingLength < 0) {
            $field
                .closest(this.classFormGroup)
                .find(this.classFormText)
                .addClass('text-danger')
            ;
        } else {
            $field
                .closest(this.classFormGroup)
                .find(this.classFormText)
                .removeClass('text-danger')
            ;
        }
    }

    /**
     * Sets the amount of available text length to the info area of the field.
     * @param {Object} $btn - jQueryObject
     * @param {Object} event - Event Object of the clicked element
     */
    handleEmptyField($btn, event) {
        event.preventDefault();

        const $field = $btn.closest(this.classFormGroup).find(this.classField);
        const maxLength = parseInt($field.attr('maxlength'), 10);

        $field.val('');

        this.handleLetterCounter($field, maxLength);
    }

    /**
     * @param {String} elements
     */
    avoidCopyPaste(elements) {
        $(elements).bind('paste', (event) => {
            event.preventDefault();
        });
    }

    showTabWithHtml5ValidationErrors() {
        const form = document.querySelector('form');
        const notifiClass = 'js-form-error-notifi';
        const submitBtn = document.querySelector('button[type="submit"]');

        if (!form) {
            return;
        }

        if (submitBtn) {
            submitBtn.addEventListener('click', () => {
                this.numberOfinvaliFormFields = 0;
                $('.' + notifiClass).remove();
            });
        }

        form.addEventListener('invalid', (event)=>{
            this.numberOfinvaliFormFields++;

            const $invalidElement = $(event.target);
            const $tabPane = $invalidElement.closest('.tab-pane');
            
            const oneLevelUp = $tabPane.parent().closest('.tab-pane');

            // Especially for hidden input elements, used by select2 and other input plugins which use real html forms in the background
            if (!$('.' + notifiClass).length) {
                $(/*html*/`
                    <div class="${notifiClass} alert alert-warning text-center"> 
                        <i style="font-size: 2rem;" class="text-danger fa-solid fa-triangle-exclamation"></i> 
                    </div>
                `).insertAfter($invalidElement);
            }

            // magic happensonly for first error
            if (this.numberOfinvaliFormFields === 1) {
                // In case the invalid element is inside a tab pane
                if ($tabPane.length > 0) {
                    let tabPaneId = $tabPane.attr('id');

                    // in case the tab pane is inside another tab pane which is the real
                    // parent we like to get the id of the parent tab pane
                    if (oneLevelUp.length > 0) {
                        tabPaneId = oneLevelUp.attr('id');
                    } 

                    // Find the corresponding tab link and show the tab
                    const $tabLink = $('a[data-bs-toggle="tab"][href="#' + tabPaneId + '"]');
                    const tabTrigger = new bootstrap.Tab($tabLink);
                    tabTrigger.show();
                }
            
                // Scroll to the invalid element and set focus
                $('html, body').animate({
                    scrollTop: $invalidElement.offset().top - 60
                }, 1000);

                $invalidElement
                    .addClass('border border-warning')
                    .focus()
                ;
            }

            return false;
            
        },true)
    }
    
    /**
     * Stuff we need to initalize
     */
    init() {
        /**
         *  To prevent conflicts with jQuery Objects 'this.
         */
        const self = this;

        /**
         * Assign methods to event handlers.
         */
        $(document).ready(() => {
            $.each($(self.classField), function () {
                self.handleLetterCounter($(this));
            });

            $(self.classField).on('input', function () {
                self.handleLetterCounter($(this));
            });

            $(self.classEmptyFieldBtn).on('click', function (event) {
                self.handleEmptyField($(this), event);
            });

            self.showTabWithHtml5ValidationErrors();

            this.avoidCopyPaste(this.elementsForAvoidCopyPaste);
        });
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new Forms();
